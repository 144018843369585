import React, { Component } from "react";
import "../styles/Uploader.css";

import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";

import Tesseract from "tesseract.js";
import { tweakPotentialPatterns, mrzRegex, analyzePattern }  from "../lib/mrz_tweaker";
import { turnDateToReadableForm }  from "../lib/mrz_parser";
import PropTypes from "prop-types";

class OcrUploader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uploaded: undefined,
            action: "Upload picture",
        };

    }
  handleChange = (event) => {
      if (event.target.files[0]) {
          let uploadedImg = undefined;
          const upload = event.target.files[0];
          uploadedImg = URL.createObjectURL(upload);

          this.setState({
              uploaded: uploadedImg,
          });

          this.generateText(uploadedImg);

      } else {
          this.setState({
              uploaded: undefined,
          });
      }
  }
  generateText = (img) => {
      const uploaded = img;
      if (!uploaded) {
          return;
      }
      this.setState({
          action: "Generating",
      });

      Tesseract.recognize(uploaded, {
          lang: "eng",
          /* eslint-disable */
          tessedit_char_whitelist: "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ< ",
          /* eslint-enable */
      })
          .catch((err) => {
              this.setState({
                  action: `Error while recognizing characters : ${err}`,
              });
          })
          .then((result) => {
              // Get mrz pattern
              const pat = mrzRegex;

              // remove spaces
              const noSpacesText = result.text.replace(/\s/g, "");

              let patterns = noSpacesText.match(pat);
              let parsed = [];

              if (patterns) {
                  patterns.forEach((pat) => {
                      parsed.push(analyzePattern(pat));
                  });
              } else if (!parsed || parsed.errors_in_mrz !== 0) {
                  // checks for potential errors in tesseract inference
                  parsed = this.checkForPotentialPatterns(noSpacesText, pat);

                  if (parsed[0]) {
                      patterns = [];
                      patterns.push(parsed[0].mrz);
                  } else {
                      patterns = [];
                  }
              }

              this.fillFormWithParsedData(parsed[0]);

              const stringDisplay = (patterns.length > 0 ? "" : "Image not good enough");
              // Update state
              this.setState({
                  action: stringDisplay,
              });
          });
  }

  checkForPotentialPatterns(noSpacesText, desiredPattern) {
      const patterns = noSpacesText.match(desiredPattern);

      if (!patterns) {
          return tweakPotentialPatterns(noSpacesText);
      }
      return patterns;
  }

  fillFormWithParsedData(parsed) {
      if (parsed)
          this.props.setFormData(parsed.parsedData["passport_number"],
              turnDateToReadableForm(parsed.parsedData["expiration_date"], true),
              turnDateToReadableForm(parsed.parsedData["date_of_birth"], false));
  }

  render() {
      return (

          <div className="ocrForm">
              <div
                  id="uploaderBox"
              >

                  <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                      className="row textAlignCenter"
                      spacing={7}
                  >
                      <Grid
                          item
                          xs={4}
                          className="text textAlignEnd"
                      >
                          <Button
                              id="uploaderBtn"
                              className="button lastButton textAlignCenter"
                              variant="contained" color="primary"
                              component="label"
                          >
                                    UPLOAD
                              <input
                                  type="file"
                                  id="fileUploader"
                                  accept=".jpg,.png,.jpeg"
                                  style={{ display: "none" }}
                                  onChange={this.handleChange}
                              />
                          </Button>
                      </Grid>

                      <Grid
                          item
                          xs={5}
                          className="rightSide"
                      >

                          { /* Current action */ }
                          <section  className="action">
                              <small>
                                  {this.state.action}
                                  {" "}
                              </small>
                          </section>

                          { /*  Display uploaded image */ }
                          <div>
                              { this.state.uploaded ?
                                  <img
                                      key="image"
                                      src={this.state.uploaded}
                                      width="100px"
                                      alt="uploadedImg"
                                      className="imagePreview"
                                  />
                                  : ""}
                          </div>

                          <Divider className="divider"/>

                      </Grid>
                  </Grid>

              </div>

          </div>
      );
  }

}

OcrUploader.propTypes = {
    setFormData: PropTypes.func,
};
export default OcrUploader;
