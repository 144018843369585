import { regexsLengths, regexs }  from "../lib/mrz_tweaker";

const mrzParts = ["document_id",
    "country",
    "name",
    "passport_number",
    "check_digit_passport",
    "nationality",
    "date_of_birth",
    "check_digit_dob",
    "sex",
    "expiration_date",
    "check_digit_expiration",
    "other",
    "check_digit_other",
    "composed_check_digit",
];

const breakLines = (string) => {
    const lines = string.split("\n");
    if (lines.length !== 2)
        throw new Error("MRZ doesn't have 2 lines");
    return lines;
};

const groupRegex = (regex, string, labels) => {
    const match = regex.exec(string);
    if (!match)
        return groupPartsRegex(string, labels);
    const result = {};
    for (let i = 1; i < match.length && i - 1 < labels.length; i++) {
        result[labels[i - 1]] = match[i];
    }
    return result;
};

const groupPartsRegex = (string, labels) => {
    let currLen = 0;
    const result = {};
    labels.forEach((label) => {
        // check index of label
        const idx = mrzParts.indexOf(label);
        const partRegex = regexs[idx];
        // cut the mrz sub-part
        const partLen = regexsLengths[idx];
        const part = string.substring(currLen, currLen + partLen);
        // match the regex
        const match = part.match(partRegex);
        result[label] = (!match ? null : match[0]);
        currLen += partLen;
    });
    return result;
};

const parseName = (string) => {
    string = string.replace(/</g, " ");
    string = string.trim();
    string = string.replace("  ", ", ");
    return string;
};

const verifyCheckDigit = (data, digit) => {
    if (!data)
        return false;

    const multiplierSequence = [7, 3, 1];
    let multiplierIndex = 0;
    let checkSum = 0;
    for (const character of data) {
        let number = 0;
        if (character >= "A" && character <= "Z")
            number = (character.charCodeAt(0) - "A".charCodeAt(0)) + 10;
        else if (character >= "0" && character <= "9")
            number = Number(character);

        checkSum += (number * multiplierSequence[multiplierIndex]);
        multiplierIndex = (multiplierIndex + 1) % multiplierSequence.length;
    }
    checkSum %= 10;
    return checkSum === Number(digit);
};

const parseFirst = (string) => {
    if (string.length !== 44)
        throw new Error("The first line does not have 44 characters");
    const regex = /^(\w{1,2})<?(\w{3})([\w<]+)$/;
    const match = groupRegex(regex, string, ["document_id", "country", "name"]);
    if (!match) {
        // throw new Error("The first line does not obey the specification");
        match["document_id"] = null;
        match["country"] = null;
        match["name"] = null;
    } else
        match["name"] = parseName(match["name"]);
    return match;
};

const parseSecond = (string) => {
    if (string.length !== 44)
        throw new Error("The second line does not have 44 characters");

    const regex = /^([\w<]{9})([0-9])([A-Z]{3})([0-9]{6})([0-9])([MF<])([0-9]{6})([0-9])([\w<]{14})(\d)(\d)$/;
    const keys = [
        "passport_number",
        "check_digit_passport",
        "nationality",
        "date_of_birth",
        "check_digit_dob",
        "sex",
        "expiration_date",
        "check_digit_expiration",
        "other",
        "check_digit_other",
        "composed_check_digit",
    ];
    let match = groupRegex(regex, string, keys);
    if (!match) {
        // throw new Error("The second line does not obey the specification");
        match = [];
        for (let k = 0; k < keys.length; k++)
            match[keys[k]] = null;
        return match;
    }

    if (!verifyCheckDigit(match["passport_number"], match["check_digit_passport"])) {
        // throw new Error("Passport number is not valid");
        match["passport_number"] = null;
        match["check_digit_passport"] = null;
    }

    if (!verifyCheckDigit(match["date_of_birth"], match["check_digit_dob"])) {
        // throw new Error("Date of birth is not valid");
        match["date_of_birth"] = null;
        match["check_digit_dob"] = null;
    }

    if (!verifyCheckDigit(match["expiration_date"], match["check_digit_expiration"])) {
        // throw new Error("Expiration date is not valid");
        match["expiration_date"] = null;
        match["check_digit_expiration"] = null;
    }

    if (!verifyCheckDigit(match["other"], match["check_digit_other"])) {
        // throw new Error("Other field is not valid");
        match["other"] = null;
        match["check_digit_other"] = null;
    }
    /* eslint-disable */
    const compositeStr = match["passport_number"] + match["check_digit_passport"] + match["date_of_birth"] + match["check_digit_dob"] + match["expiration_date"] + match["check_digit_expiration"] + match["other"] + match["check_digit_other"];
    /* eslint-enable */
    if (!verifyCheckDigit(compositeStr, match["composed_check_digit"])) {
        // throw new Error("Composite check digit is not valid");
        match["composed_check_digit"] = null;
    }
    if (match["sex"])
        match["sex"] = match["sex"] === "<" ? undefined : match["sex"];
    if (match["other"])
        match["other"] = match["other"].replace(/</g, " ").trim();
    return match;
};

const parseMRZ = (mrzString) => {
    const lines = breakLines(mrzString);
    const parsedFirstLine = parseFirst(lines[0]);
    const parsedSecondLine = parseSecond(lines[1]);

    const obj = { ...parsedFirstLine, ...parsedSecondLine };
    return obj;
};

// puts expiration and dob strings in YYYY-MM-DD format
const turnDateToReadableForm = (date, isExpirationDate) => {
    let dateString = "";
    if (date.length === 6) {
        dateString =
            `${Number(date.substring(0, 2)) > 20 && !isExpirationDate ? `19${date.substring(0, 2)}` : `20${date.substring(0, 2)}`
            }-${date.substring(2, 4)}-${date.substring(4, 6)}`;
    }
    return dateString;
};

export { parseMRZ, breakLines, parseName, groupRegex, parseFirst, parseSecond, verifyCheckDigit, mrzParts, turnDateToReadableForm };
