import config from "../envConfig";

const clearInfo = () =>
    fetch(`${config.apiUri}/clearInfo`, {
        method: "get",
        credentials: "include",
    })
        .catch((error) => error);

export default clearInfo;
