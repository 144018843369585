import config from "../envConfig";
const axios = require("axios");


const passport = (formdata) =>


    axios({
        method: "post",
        url: "http://localhost:3030",
        mode: "no-cors",
        headers: { "Content-Type": "application/json" },
        data: formdata,
    })
        .then((response) => {

            if (response.data.error) {
                return { data: "?auth=Passport&success=false" };
            }

            const mrz = response.data.dg1Data;

            const form = new FormData();
            form.append("MRZ", mrz);

            return axios({
                method: "post",
                url: `${config.apiUri}/passport`,
                mode: "no-cors",
                withCredentials: true,
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                },
                data: form,
            });
        })
        .catch((error) => error);


export default passport;
