import { parseMRZ, mrzParts } from "./mrz_parser";

/* eslint-disable */
const mrzRegex = /([A-Z])([A-Z0-9<])([A-Z]{3})([A-Z<]{39})([A-Z0-9<]{9})([0-9])([A-Z]{3})([0-9]{6})([0-9])([MF<])([0-9]{6})([0-9])([A-Z0-9<]{14})([0-9])([0-9])/g;
/* eslint-enable */

const regexs = [
    /([A-Z])([A-Z0-9<])/, // document_id
    /([A-Z]{3})/, // country
    /([A-Z<]{39})/, // name
    /([A-Z0-9<]{9})/, // passport_number
    /([0-9])/, // check_digit_passport
    /([A-Z]{3})/, // nationality
    /([0-9]{6})/, // date of birth
    /([0-9])/, // check digit date of birth
    /([MF<])/, // sex
    /([0-9]{6})/, // expiration date
    /([0-9])/, // check digit expiration date
    /([A-Z0-9<]{14})/, // other
    /([0-9])/, // check_digit_other
    /([0-9])/]; // composed_check_digit

const regexsLengths = [
    2,
    3,
    39,
    9,
    1,
    3,
    6,
    1,
    1,
    6,
    1,
    14,
    1,
    1,
];

const mrzLength = 88;
const initialMinimumRegex = /([A-Z])([A-Z0-9<])([A-Z]{3})/;
const initialMinimumLength = 5;
const finalMinimumRegex = /([0-9])([0-9])/;
const finalMinimumLength = 2;

const MAX_ERRORS_TOLERATED = 5;

// Array of characters that are able to be mutated :
// to add more, add 2 elements, representing tuples
// most frequent errors are :
// C - 6 ; 2 - Z ; O - 0 ; 5 - B;
const mostCommonMisreads = ["C", "6", "2", "Z", "O", "0", "5", "B"];

const constructMiddleRegex = (len) => new RegExp(
    `([A-Z0-9<]){${len}}`,
    "g");

const  constructMinimumViableRegex = (initialMinimumRegex, middleRegex, finalMinimumRegex) => new RegExp
(`(${initialMinimumRegex.source}${middleRegex.source}${finalMinimumRegex.source})`, "g");

const  insertNewLineInMrz = (mrz) => {
    const newMrz = `${mrz.substring(0, 44)}\n${mrz.substring(44, 88)}`;
    if (newMrz.length === 89)
        return newMrz;
    else return null;
};

const  removeNewLineInMrz = (mrz) => mrz.replace("\n", "");

const tweakPotentialPatterns = (noSpaces) => {
    const middleRegexLength = mrzLength - initialMinimumLength - finalMinimumLength;
    const middleRegex = constructMiddleRegex(middleRegexLength);
    const minimumViableRegex = constructMinimumViableRegex(initialMinimumRegex, middleRegex, finalMinimumRegex);
    const minimumViablePatterns = [];
    let match;
    // matches all minimum viable mrzs found
    /* eslint-disable */
    while (match = minimumViableRegex.exec(noSpaces)) {
    /* eslint-enable */
        minimumViablePatterns.push(match[0]);
        minimumViableRegex.lastIndex = match.index + 1;
    }

    // analyses every potential mrzs
    const patterns = [];
    minimumViablePatterns.forEach((element) => {
        const analyzedPattern = analyzePattern (element);
        patterns.push(analyzedPattern);
    });

    return pointOutPatternToTweak(patterns);
};

// filters which potential patterns should be mutated
// and actually tweaks them
const pointOutPatternToTweak = (patterns) => {
    const tweakedPatterns = [];
    patterns.forEach((pattern) => {
        if (pattern.errorsInMrz <= MAX_ERRORS_TOLERATED) {
            tweakedPatterns.push(tweakPattern(pattern));
        }
    });
    return tweakedPatterns;
};


const tweakPattern = (pat) => {
    const mutableSubStringsInPattern = [];  // candidates to mutate
    pat.mrz = removeNewLineInMrz(pat.mrz);
    for (const data in pat.parsedData) {
        // check where data is null
        if (!pat.parsedData[data]) {
            mutableSubStringsInPattern.push(checkSubstringToTweak(data, pat.mrz));
        }
    }
    // actually mutate, where part is the null mrz part
    mutableSubStringsInPattern.forEach((part) => {
        // if a check_digit is null, probably it's just the field it's checking that needs tweaking
        if (!part.mrzPart.includes("check_digit")) {

            const currErrors = pat.errorsInMrz;
            // possible mutations - indexes of the char
            const mutations = getMutationsForSubPart(part.subString);
            // all possible combinations of the mutations available
            const mutationsCombinations = findAllCombinationsOfMutations(mutations);
            // counter of the times the loop is executed
            let counter = 0;
            // while ends when successful mutation is achieved or no more mutations are possible
            let mutationSuccessful = false;
            let tweakedPat;
            do {
                // the whole pottential mrz pattern that will be tweaked
                tweakedPat = copyPotentialPattern(pat);
                // part of the pattern that will be tweaked
                const partToBeTweaked = copyPatternPart(part);
                tweakSubPart(partToBeTweaked, mutationsCombinations[counter]);
                // so that it will be analyzed after
                tweakedPat.mrz = replaceAt (tweakedPat.mrz, partToBeTweaked.charStartAt, partToBeTweaked.subString);
                // check nro of errors
                tweakedPat = analyzePattern(tweakedPat.mrz);
                mutationSuccessful = tweakedPat.errorsInMrz < currErrors;
                // only tries a bigger ammount of mutations if all mutations
                // of r = counter + 1 were executed ==> can go through regex but not be valid
                if ((!mutationSuccessful && mutationsCombinations[counter].length <= 0))
                    counter++;
            } while (!mutationSuccessful && counter < mutationsCombinations.length);

            if (mutationSuccessful) {
                pat = copyPotentialPattern(tweakedPat);
            }
        }

    });

    return pat;
};

const tweakSubPart = (part, mutations) => {
    // regex of the mrz part
    const regexPart = regexs[mrzParts.indexOf(part.mrzPart)];
    // mutations tried
    const counter = 0;
    let tweakedString;
    do {
        tweakedString = part.subString;
        tweakedString = executeMutation(tweakedString, mutations[counter]);
        // remove from mutations
        mutations.splice(counter, 1);
    } while (!tweakedString.match(regexPart) && mutations.length !== 0);
    part.subString = tweakedString;
};


// most frequent errors are :
// C - 6 ; 2 - Z ; O - 0 ; 5 - B;
const executeMutation = (str, mutation) => {
    if (!mutation)
        return str;
    mutation.forEach((mut) => {
        const charChange = findMutationToBeMade(str[mut]);
        str = replaceAt(str, mut, charChange);
    });
    return str;
};

// retreives substring of point of failure in mrz
const checkSubstringToTweak = (data, mrz) => {
    const index = mrzParts.indexOf(data);
    let charStartAt = 0;
    for (let i = 0; i < index; i++) {
        charStartAt += regexsLengths[i];
    }
    const charEndAt = charStartAt + regexsLengths[index];
    const subString = mrz.substring(charStartAt, charEndAt);
    return  { charStartAt: charStartAt, mrzPart: data, subString: subString };
};

// parses mrz and checks where the parsing failed
const analyzePattern = (pattern) => {
    const mrz = (pattern[44] === "\n" ? pattern : insertNewLineInMrz(pattern));
    if (mrz) {
        const data = parseMRZ(mrz);
        let errors = mrzParts.length;
        // number of errors in mrz
        for (const dataElement in data) {
            if (data[dataElement])
                errors--;
        }
        return { mrz: mrz, parsedData: data, errorsInMrz: errors };
    }
    return {};
};

const replaceAt = (string, index, replace) => {
    if (string.length >= 88)
        string = (string[44] === "\n" ? removeNewLineInMrz(string) : string);
    return string.substring(0, index) + replace + string.substring(index + replace.length);
};

const getMutationCount = (charSearch, str, mutations) => {
    for (let count = -1, index = -2; index !== -1; count++, index = str.indexOf(charSearch, index + 1))
        if (index >= 0)mutations.push(index);
};

const getMutationsForSubPart = (substr) => {
    const mutations = [];
    for (const c of mostCommonMisreads) {
        getMutationCount(c, substr, mutations);
    }
    return mutations;
};

const findMutationToBeMade = (char) => {
    const idx = mostCommonMisreads.indexOf(char);
    return (idx % 2 === 0 ? mostCommonMisreads[idx + 1] : mostCommonMisreads[idx - 1]);
};

/*
str - Input Array
data - Temporary array to store current combination
start ,end - Staring and Ending indexes in str
index  -- Current index in data
r - Size of a combination to be printed */
const getCombination = (str, data, start, end, index, r, combs) => {
    const combsAux = [];
    if (index === r) {
        for (let j = 0; j < r; j++)
            combsAux.push(data[j]);
        combs.push(combsAux);
        return;
    }

    for (let i = start; i <= end && end - i + 1 >= r - index; i++) {
        data[index] = str[i];
        getCombination(str, data, i + 1, end, index + 1, r, combs);
    }
};

const findCombinations = (str, n, r, combs) => {
    const data = [];
    getCombination(str, data, 0, n - 1, 0, r, combs);
};

// Gets all possible combinations of mutations to be executed
const findAllCombinationsOfMutations = (str) => {
    let combsR = [];
    const combsTotal = [];
    for (let i = 1; i <= str.length; i++) {
        findCombinations(str, str.length, i, combsR);
        combsTotal.push(combsR);
        combsR = [];
    }
    return combsTotal;
};

const copyPotentialPattern = (pat) => {
    const pattern = {};
    pattern.errorsInMrz = pat.errorsInMrz;
    pattern.mrz = pat.mrz;
    pattern.parsedData = pat.parsedData;
    return pattern;
};


const copyPatternPart = (part) => {
    const partCopy = {};
    partCopy.charStartAt = part.charStartAt;
    partCopy.mrzPart = part.mrzPart;
    partCopy.subString = part.subString;
    return partCopy;
};

export { tweakPotentialPatterns, mrzRegex, regexsLengths,
    regexs, analyzePattern, executeMutation, replaceAt, findAllCombinationsOfMutations };
