import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid, Card, CardContent, Button, Typography, Input } from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import getInfo from "../api/getInfo";
import clearInfo from "../api/clearInfo";
import "../styles/App.css";

const useStyles = makeStyles((theme) => ({
    input: {
        border: 0,
        borderBottom: "2px dotted",
        borderBottomColor: "rgb(163, 163, 163)",
    },
    typo: {
        fontWeight: "bold",
        textTransform: "uppercase",
        color: "rgb(88, 147, 245)",

        [theme.breakpoints.down("md")]: {
            textAlign: "center",
        },
    },
    title: {
        display: "grid",
        alignItems: "center",
    },
    card: {
        width: "50%",
        margin: "auto",
        marginTop: 50,
    },
    button: {
        marginLeft: "auto",
        marginRight: 0,
        display: "block",
    },
    status: {
        marginTop: "1rem",
        marginBottom: "1rem",
    },
    icon: {
        marginRight: "10px",
        marginBottom: "-10px",
        display: "inline-block",
    },
    success: {
        color: "rgb(76, 175, 80)",
    },
    error: {
        color: "rgb(198, 40, 40)",
    },
}));

const AuthStatus = (props) => {

    const classes = useStyles();

    if (props.success === "true")
        return (
            <React.Fragment>
                <CheckCircleOutlineIcon className={classes.icon} fontSize="large" />
                {" "}
Verified
            </React.Fragment>
        );
    else
        return (
            <React.Fragment>
                <HighlightOffIcon className={classes.icon} fontSize="large" />
                {" "}
Error
            </React.Fragment>
        );
};

function InformationForm(props) {

    const classes = useStyles();

    const [name, setName] = useState("");
    const [birthday, setBirthday] = useState("");
    const [documentID, setDocumentID] = useState("");
    const [expirationDate, setExpirationDate] = useState("");

    useEffect(() => {
        getInfo().then((result) => {
            if (result.user_info !== null) {
                const userInfo = JSON.parse(result.user_info);
                setName(userInfo.name || "");
                setBirthday(userInfo.birthday || "");
                setDocumentID(userInfo.documentID || "");
                setExpirationDate(userInfo.expirationDate || "");
            }
        });
    }, []);

    const param = new URLSearchParams(props.location.search);
    const auth = param.get("auth") || "";
    const success = param.get("success") || "false";

    const onClick = () => {
        clearInfo();
    };

    return (
        <Card className={classes.card}>
            <CardContent>
                <form id="infoform">
                    <Grid container justify="space-around" spacing={2}>
                        <Grid item xs={10} />
                        <Grid item xs={2}>
                            <Link className="link" to="/">
                                <Button className={classes.button} onClick={onClick} >
                                    <Typography className={classes.title}>
                                        Reset
                                    </Typography>
                                </Button>
                            </Link>
                        </Grid>

                        <Grid item md={1} />
                        <Grid className={classes.title} item xs={12} md={3}>
                            <Typography className={classes.typo} variant="subtitle2" align="right">
                                Auth method
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Input
                                className={classes.input}
                                required
                                fullWidth
                                disableUnderline
                                disabled
                                value={auth}
                            />
                        </Grid>
                        <Grid item md={2} />


                        <Grid item md={1} />
                        <Grid className={classes.title} item xs={12} md={3}>
                            <Typography className={classes.typo} variant="subtitle2" align="right">
                                Name
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Input
                                className={classes.input}
                                required
                                fullWidth
                                disableUnderline
                                disabled
                                value={name}
                            />
                        </Grid>
                        <Grid item md={2} />

                        {/* ----- */}

                        <Grid item md={1} />
                        <Grid className={classes.title} item xs={12} md={3}>
                            <Typography className={classes.typo} variant="subtitle2" align="right">
                                Date of birth
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Input
                                className={classes.input}
                                required
                                fullWidth
                                disableUnderline
                                disabled
                                value={birthday}
                            />
                        </Grid>
                        <Grid item md={2} />

                        {/* ----- */}

                        <Grid item md={1} />
                        <Grid className={classes.title} item xs={12} md={3}>
                            <Typography className={classes.typo} variant="subtitle2" align="right">
                                Document ID
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Input
                                className={classes.input}
                                required
                                fullWidth
                                disableUnderline
                                disabled
                                value={documentID}
                            />
                        </Grid>
                        <Grid item md={2} />

                        {/* ----- */}

                        <Grid item md={1} />
                        <Grid className={classes.title} item xs={12} md={3}>
                            <Typography className={classes.typo} variant="subtitle2" align="right">
                                Expiration date
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Input
                                className={classes.input}
                                required
                                fullWidth
                                disableUnderline
                                disabled
                                value={expirationDate}
                            />
                        </Grid>
                        <Grid item md={2} />

                        <Grid item xs={12} className={classes.status}>
                            <Typography variant="h6" className={success === "true" ? classes.success : classes.error}>
                                <AuthStatus success={success} />
                            </Typography>
                        </Grid>
                    </Grid>
                </form>
            </CardContent>
        </Card>
    );
}

InformationForm.propTypes = {
    location: PropTypes.object.isRequired,
};

AuthStatus.propTypes = {
    success: PropTypes.string.isRequired,
};

export default InformationForm;
