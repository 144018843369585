import React, { useState } from "react";
import { Button } from "@material-ui/core";
import portugueseId from "../api/portugueseId";

function CmdButton() {
    const [saml, setSAML] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault();
        event.persist();

        portugueseId()
            .then((response) => {
                setSAML(response);
                event.target.submit();
            });
    };

    return (
        <div>
            <p>Login with Digital Mobile Key or Citizen Card:</p>

            <form
                method="POST"
                action="https://preprod.autenticacao.gov.pt/fa/Default.aspx"
                onSubmit={handleSubmit}
            >
                <input
                    type="hidden"
                    name="SAMLRequest"
                    value={saml}
                />
                <Button
                    type="submit"
                    variant="contained"
                    className="portuguese-auth-color"
                >
                    <img
                        src={require("../images/bot_autenticacao_br.png")}
                        alt="Portuguese id authentication logo"
                    />
                </Button>
            </form>
        </div>
    );
}

export default CmdButton;
