import config from "../envConfig";

const portugueseId = () =>
    fetch(`${config.apiUri}/portugueseid`, {
        method: "get",
    })
        .then((response) => response.json())
        .catch((error) => error);

export default portugueseId;
