import React from "react";
import PropTypes from "prop-types";
import "../styles/PassportForm.css";

import { withRouter, Link } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Paper } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import OcrUploader from "./OcrUploader";
import Button from "@material-ui/core/Button";


import passport from "../api/passport";

class PassportForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = { passportID: "", expirationDate: "", dateOfBirth: "" };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const name = event.target.name;

        this.setState({ [name]: event.target.value });
    }

    handleSubmit(event) {
        event.preventDefault();

        const form = new FormData();
        form.append("passportID", this.state.passportID);
        form.append("expirationDate", this.state.expirationDate);
        form.append("dateOfBirth", this.state.dateOfBirth);


        const data = JSON.stringify({
            passportID: this.state.passportID,
            expirationDate: this.state.expirationDate,
            dateOfBirth: this.state.dateOfBirth,
        });

        passport (data)
            .then((response) =>
                this.props.history.push(`/information${response.data}`)
            );
    }


    setFormData(passportID, expirationDate, dateOfBirth) {
        // removes < chars
        passportID = passportID.replace(/</g, "");
        this.setState({
            passportID: passportID,
            expirationDate: expirationDate,
            dateOfBirth: dateOfBirth,
        });
    }

    render() {
        return (
            <div className="PassportForm">
                <Box id="page">
                    <Paper
                        id="box"
                        m={1}
                    >
                        <Grid id="grid" container direction="column" justify="center" alignItems="center" spacing={10}>
                            <form id="form" onSubmit={this.handleSubmit}>
                                <Grid container justify="space-around" spacing={2}>
                                    <Grid item xs={8} />
                                    <Grid item xs={3}>
                                        <Link className="link" to="/">
                                            <Button className="reset-button">
                                                <Typography >
                                                    Reset
                                                </Typography>
                                            </Button>
                                        </Link>
                                    </Grid>
                                    <Grid container direction="row" justify="center" alignItems="center" className="row textAlignCenter">
                                        <OcrUploader
                                            setFormData={this.setFormData.bind(this)}
                                        />

                                    </Grid>
                                </Grid>
                                <Grid className="row" container direction="row" justify="center" alignItems="center" >
                                    <Grid item xs={5} className="text textAlignEnd" >
                                        <label> PASSPORT ID</label>
                                    </Grid>

                                    <Grid item xs={7} className="text">

                                        <TextField
                                            id="passportID" className="text passportID" name="passportID"
                                            maxLength="9" required value={this.state.passportID}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container className="row" direction="row" justify="center" alignItems="center">

                                    <Grid item xs={5} className="text textAlignEnd">
                                        <label> EXP. DATE</label>
                                    </Grid>
                                    <Grid className="text" item xs={7}>
                                        <TextField
                                            required className="text inputField" name="expirationDate" type="date"
                                            value={this.state.expirationDate} onChange={this.handleChange}
                                        />

                                    </Grid>
                                </Grid>


                                <Grid container className="row" direction="row" justify="center" alignItems="center">
                                    <Grid item xs={5} className="text textAlignEnd">
                                        <label> DATE OF BIRTH</label>
                                    </Grid>
                                    <Grid className="text" item xs={7}>
                                        <TextField
                                            required className="text inputField" name="dateOfBirth" type="date"
                                            value={this.state.dateOfBirth} onChange={this.handleChange}
                                        />

                                    </Grid>
                                </Grid>

                                <Grid item xs={12} className="textAlignCenter">
                                    <Button
                                        className="button lastButton textAlignCenter"
                                        variant="contained" color="primary"
                                        component="label"
                                    >
                                                GET INFO
                                        <input
                                            type="submit"
                                            id="submitForm"
                                            style={{ display: "none" }}
                                        />
                                    </Button>
                                </Grid>
                            </form>
                        </Grid>

                    </Paper>
                </Box>


            </div>
        );
    }
}

PassportForm.propTypes = {
    history: PropTypes.object.isRequired,
};

export default withRouter(PassportForm);
