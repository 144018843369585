import config from "../envConfig";

const getInfo = () =>
    fetch(`${config.apiUri}/getInfo`, {
        method: "get",
        credentials: "include",
    })
        .then((response) => response.json())
        .catch((error) => error);

export default getInfo;
