import React from "react";
import "./styles/App.css";
import CmdButton from "./components/CmdButton";
import PassportForm from "./components/PassportForm";
import InformationForm from "./components/InformationForm";
import Button from "@material-ui/core/Button";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
} from "react-router-dom";

function App() {

    return (

        <div className="App">
            <Router>
                <Route exact path="/">
                    <CmdButton />
                    <Link className="link" to="/passportform">
                        <Button className="buttonLink" variant="contained" color="primary">PassportForm</Button>
                    </Link>
                </Route>

                <Switch>
                    <Route path="/passportform">
                        <PassportForm />
                    </Route>
                    <Route path="/information" component={InformationForm} />
                </Switch>


            </Router>

        </div>

    );
}

export default App;
